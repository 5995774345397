<template>
  <div class="practice-box">
    <template v-if="question">
      <template v-if="page === 1">
        <TestingTools_M
          @pageEnd="nextPage"
          @returnToHome="returnToHome"
          :showCalculator="showCalculator"
          :showReference="showReference"
          :practiceType="practiceType"
        />
      </template>
      <template v-if="page === 2">
        <template v-if="showQuestion">
          <QuestionWrapper_M
            ref="QuestionWrapper"
            :questions="questions"
            :sectionOrder="sectionOrder"
            :sectionName="titleCase(sectionName)"
            :examineeName="examineeName"
            :questionOrder="questionOrder"
            :questionCount="questions.length"
            :examTimeSeconds="testTime"
            :isReviewPage="isReviewPage"
            :hasTime="hasTime"
            @onBackQuestion="onBackQuestion"
            @onNextQuestion="onNextQuestion"
            @onOrderQuestion="onOrderQuestion"
            @onSubmitSection="onSubmitSection"
            @onTestTimeout="onTestTimeout"
            @onExitTest="onExitTest"
            @onToReviewPage="onToReviewPage"
            @onReviewPage="onReviewPage"
            @setPassage="setPassage"
            :showAnnotate="
              sectionName !== 'math' && sectionName !== 'math calculator'
            "
            :showCalculator="
              sectionName === 'math calculator' ||
                (isNew && sectionName === 'math')
            "
            :showReference="
              sectionName === 'math' || sectionName === 'math calculator'
            "
            :formAnnotation="
              question &&
              question.user_exam_answer_other_status &&
              question.user_exam_answer_other_status.annotation &&
              question.user_exam_answer_other_status.annotation.annotation
                ? question.user_exam_answer_other_status.annotation.annotation
                : null
            "
          >
            <div class="test-paper">
              <template v-if="isReviewPage">
                <Review_M
                  :questions="questions"
                  :sectionOrder="sectionOrder"
                  :sectionName="titleCase(sectionName)"
                  @onOrderQuestionFromReview="onOrderQuestionFromReview"
                ></Review_M>
                <!-- <SATReviewPage></SATReviewPage> -->
              </template>
              <template v-else>
                <MultipleChoiceWithPassage_M
                  v-if="
                    question.question &&
                      question.question.type === 'default' &&
                      question.question.sat_passage_id > 0 &&
                      question.question.sat_passage.content
                  "
                  :key="question.order"
                  :passageIntro="
                    question.question.sat_passage.introduction && !this.isNew
                      ? question.question.sat_passage.introduction.intro
                      : null
                  "
                  :passageContent="getPassage"
                  :questionOrder="questionOrder"
                  :content="question.question.content"
                  :options="question.question.sat_options"
                  :answer="answer"
                  :marked="marked"
                  :crossOutActive="crossOutActive"
                  :crossOutArr="crossOutArr"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                  @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
                  @onChangeCrossOut="onChangeCrossOut"
                  :showLine="sectionName === 'reading'"
                ></MultipleChoiceWithPassage_M>
                <MultipleChoice_M
                  v-else-if="
                    question.question &&
                      question.question.type === 'default' &&
                      (question.question.sat_passage === null ||
                        question.question.sat_passage.content === null)
                  "
                  :questionOrder="questionOrder"
                  :content="question.question.content"
                  :options="question.question.sat_options"
                  :answer="answer"
                  :marked="marked"
                  :crossOutActive="crossOutActive"
                  :crossOutArr="crossOutArr"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                  @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
                  @onChangeCrossOut="onChangeCrossOut"
                ></MultipleChoice_M>
                <Math_M
                  v-else-if="question.question.type === 'math'"
                  :questionOrder="questionOrder"
                  :content="question.question.content"
                  :options="question.question.sat_options"
                  :answer="answer"
                  :marked="marked"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                ></Math_M>
              </template>
            </div>
          </QuestionWrapper_M>
        </template>
        <template v-else>
          <BreakScreen_M
            :examineeName="examineeName"
            :breakTimeSeconds="
              breakTime[`section${sectionOrder - 1}${sectionOrder}`].time
            "
            @onBreakTimeout="showQuestion = true"
          />
        </template>
      </template>
      <el-dialog
        :title="this.$t('message.notice')"
        :visible.sync="showLeaveAlert"
        width="30%"
        :close-on-click-modal="false"
        center
      >
        <div>
          <h6
            v-html="
              $t('toefl.leaveAlert', {
                counts: this.leaveCountText,
                times: this.leaveSeconds,
                second: this.second
              })
            "
          ></h6>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            size="small"
            @click="showLeaveAlert = false"
          >
            {{ this.$t("message.continue") }}
          </el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import $ from "jquery";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import common from "@/mixins/common";
import { user } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "Test - " + this.CompanyName
    };
  },

  components: {},

  mixins: [common],

  props: [],
  data() {
    return {
      timeType: 1,
      isReviewPage: false,
      userExamAnswers: null,
      testTime: 0,
      adaptivePractice: null,
      sectionOrder: 1,
      page: 0,
      questionOrder: 1,
      practice: null,
      questions: null,
      answer: "",
      marked: false,
      crossOutActive: false,
      crossOutArr: [],
      userExamId: 0,
      practices: [],
      showQuestion: true,
      examId: 0,
      showLeaveAlert: false,
      spent: 0,
      leaveSeconds: 0,
      leaveTimes: 0,
      questionTimeLine: 0,
      isNew: false,
      other_status: null,
      activities: [],
      annotation: {
        passage: null,
        annotation: []
      },
      subjects: [],
      question: null,
      adaptiveLine: {
        first_correct_count: 0,
        last_correct_count: 0,
        line: {
          first: null
        }
      }
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    ...mapGetters("user", ["token", "getProfile"]),
    sectionName() {
      let subject_id = null;
      let sectionName = "";
      // if (this.question.question.sat_subject.id > 0) {
      //   subject_id = this.question.question.sat_subject.id;
      // } else {
      //   subject_id = this.practice.subject_id;
      // }
      subject_id = this.practice.subject_id;
      this.subjects.forEach(subject => {
        if (subject.id == subject_id) {
          sectionName = subject.name;
        }
      });
      return sectionName;
    },
    breakTime() {
      if (this.isNew) {
        return {
          section12: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section34: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      } else {
        return {
          section12: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 5 * this.timeType : 5
          },
          section34: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      }
    },
    instant() {
      return instant;
    },
    showCalculator() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    showReference() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 3 || this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    pastUserExamId() {
      return this.$route.query.user_exam_id;
    },
    examineeName() {
      const { first_name, last_name } = this.$store.getters["user/getProfile"];
      return user.displayName(first_name, last_name);
    },
    getPassage() {
      let passage = "";
      let p1 = "";
      if (
        this.question.user_exam_answer_other_status &&
        this.question.user_exam_answer_other_status.annotation
      ) {
        p1 = this.question.user_exam_answer_other_status.annotation.passage;
      } else {
        p1 = this.question.question.sat_passage.content;
      }
      let p2 = this.question.question.sat_passage.introduction
        ? this.question.question.sat_passage.introduction.below_content
        : null;
      if (p2) {
        passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
      } else {
        passage = p1;
      }
      // passage = this.getLine(passage);
      return passage;
    },
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    testId() {
      return this.$route.params.id;
    },
    practiceType() {
      return this.$route.params.practiceType;
    },
    hasTime() {
      return (
        !(
          this.practice &&
          this.practice.marks &&
          this.practice.marks[1] &&
          this.practice.marks[1].name.en === "drill"
        ) && this.timeType > 0
      );
    }
  },
  watch: {
    questionOrder() {
      this.setQuestion();
    },
    page() {
      this.questionTimeLine = this.question.answer_time
        ? this.question.answer_time
        : 0;
    }
  },

  async mounted() {
    // this.addLineNumber();
    //不让选中
    document.addEventListener("contextmenu", function(e) {
      e.preventDefault();
    });
    window.addEventListener("beforeunload", e => this.beforeUnloadHandler(e));
    window.addEventListener("unload", e => this.unloadHandler(e));
    setInterval(() => {
      this.questionTimeLine++;
    }, 1000);
    setInterval(() => {
      this.spent++;
    }, 1000);
    let vm = this;
    let leaveStart = 0;
    window.onblur = function() {
      vm.showLeaveAlert = false;
      if (vm.$route.name == "SATTest") {
        leaveStart = vm.spent;
        vm.leaveTimes++;
        document.title =
          "Warning: Do not leave the browser in the middle of the test";
      }
    };
    window.onfocus = function() {
      vm.leaveSeconds += vm.spent - leaveStart;
      if (vm.$route.name == "SATTest") {
        document.title = "Test - " + this.CompanyName;
        if (vm.leaveSeconds > 0) {
          vm.showLeaveAlert = true;
        }
      }
    };

    let testId = this.$route.params.id;
    if (testId) {
      if (this.practiceType === "composePractices") {
        const res = await SAT.getComposePracticeTest(testId);
        this.practices = res.compose_practice.practices;
        this.practice = this.practices[this.sectionOrder - 1];
        this.questions = this.practice.exam.exam_questions;
        this.examId = res.compose_practice.exam.id;
        // if (res.compose_practice.marks[0].name.en === "new") {
        //   this.isNew = true;
        // }
        this.isNew = true;
      }
      if (this.practiceType === "adaptivePractices") {
        const res = await SAT.getAdaptive(testId);
        this.adaptivePractice = res.adaptive_practice;
        this.adaptiveLine.first_correct_count =
          res.adaptive_practice.first_correct_count;
        this.adaptiveLine.last_correct_count =
          res.adaptive_practice.last_correct_count;
        if (this.pastUserExamId) {
          this.userExamId = this.pastUserExamId;
          const data = await SAT.getPastComposePractice(this.userExamId);
          // this.examId = data.adaptive_practice.compose_practices[0].exam.id;
          this.examId = data.user_exam.exam_id;
          if (
            res.adaptive_practice.compose_practices &&
            res.adaptive_practice.compose_practices.length > 0
          ) {
            res.adaptive_practice.compose_practices.forEach((item, index) => {
              if (item.exam.id === this.examId) {
                this.practices = item.practices;
              }
            });
          }
          console.log(this.practices);
          this.practice = this.practices[this.sectionOrder - 1];
          this.questions = this.practice.exam.exam_questions;
          this.setUserTest(data);
        }
        this.isNew = true;
      } else if (this.practiceType === "singlePractice") {
        const res = await SAT.getPracticeTest(testId);
        this.practices = [res.practice];
        this.practice = this.practices[this.sectionOrder - 1];
        this.questions = this.practice.exam.exam_questions;
        this.examId = this.practice.exam.id;
        // if (this.composePractice.marks[0] && res.practice.marks[0].name.en === "new") {
        //   this.isNew = true;
        // }
        this.isNew = true;
      }
    }
    await this.startTest();
    this.testTime = await this.setExamTimeSeconds();
    console.log(this.testTime);
    const res = await SAT.getSubjects();
    this.subjects = res.sat_subjects;
    // if(this.$refs.QuestionWrapper){
    //   this.$refs.QuestionWrapper.setHighlight();
    // }
  },
  destroyed() {
    window.removeEventListener("beforeunload", e =>
      this.beforeUnloadHandler(e)
    );
    window.removeEventListener("unload", e => this.unloadHandler(e));
  },

  methods: {
    beforeUnloadHandler(e) {
      // this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     this.$message({
      //       type: "success",
      //       message: "删除成功!"
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除"
      //     });
      //   });
      e.returnValue = "确定离开页面吗？";
      return " ";
    },
    unloadHandler(e) {
      // this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     this.$message({
      //       type: "success",
      //       message: "删除成功!"
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除"
      //     });
      //   });
      e.returnValue = "确定离开页面吗？";
      return " ";
    },
    setSection() {
      this.practice = this.practices[this.sectionOrder - 1];
      this.questions = this.practice.exam.exam_questions;
    },
    setQuestion() {
      if (this.questions) {
        let question = this.questions[this.questionOrder - 1];
        if (question.question["answer"]) {
          question.question["answer"];
          question["answer"] = question.question["answer"];
        } else {
          question.question["answer"] = "";
          question["answer"] = undefined;
        }
        if (question["isMarked"]) {
          question["isMarked"];
        } else {
          question["isMarked"] = false;
        }
        if (question["crossOutActive"]) {
          question["crossOutActive"];
        } else {
          question["crossOutActive"] = false;
        }
        if (question["crossOutArr"]) {
          question["crossOutArr"];
        } else {
          question["crossOutArr"] = [];
        }
        if (!question.question.sat_subject) {
          question.question.sat_subject = {
            id: 5,
            name: "english"
          };
        }
        this.question = question;
        this.answer = this.question.question["answer"];
        this.marked = this.question["isMarked"];
        this.crossOutActive = this.question["crossOutActive"];
        this.crossOutArr = this.question["crossOutArr"];
        this.questionTimeLine = this.question.answer_time
          ? this.question.answer_time
          : 0;
        this.question.user_exam_answer_other_status.activities.push([
          {
            testTime: this.$refs.QuestionWrapper
              ? this.$refs.QuestionWrapper.remainExamTimeSeconds
              : this.testTime,
            content: "Started question",
            type: "START"
          }
        ]);
        console.log(this.question.user_exam_answer_other_status.annotation);
        if (
          this.question.user_exam_answer_other_status &&
          this.question.user_exam_answer_other_status.annotation
        ) {
          if (this.question.question.sat_passage) {
            this.question.question.sat_passage.content = this.question
              .user_exam_answer_other_status.annotation.passage
              ? this.question.user_exam_answer_other_status.annotation.passage
              : this.question.question.sat_passage.content;
          }
          this.question.question.content = this.question
            .user_exam_answer_other_status.annotation.question
            ? this.question.user_exam_answer_other_status.annotation.question
            : this.question.question.content;

          if (this.question.question.sat_options) {
            this.question.question.sat_options.forEach((option, index) => {
              option.title = this.question.user_exam_answer_other_status.annotation.options[
                index
              ].title;
            });
          }
        }
        if (this.$refs.QuestionWrapper) {
          this.$refs.QuestionWrapper.setHighlight();
        }
      }
    },
    setNextSection() {
      this.setSection();
      this.questionOrder = 1;
      this.testTime = this.setExamTimeSeconds();
    },
    setExamTimeSeconds() {
      let testTime = 0;
      if (this.isNew) {
        if (
          this.sectionName === "math calculator" ||
          this.sectionName === "math"
        ) {
          testTime = this.timeType ? this.timeType * 35 * 60 : 35 * 60;
        } else {
          if (this.questions && this.questions.length === 27) {
            testTime = this.timeType ? this.timeType * 32 * 60 : 32 * 60;
          } else if (this.questions && this.questions.length === 33) {
            testTime = this.timeType ? this.timeType * 33 * 60 : 33 * 60;
          } else {
            testTime = this.timeType
              ? this.timeType * this.questions.length * 60 * (32 / 27)
              : this.questions.length * 60 * (32 / 27);
          }
        }
      } else {
        testTime = this.timeType
          ? this.timeType * this.questions.length * 60
          : this.questions.length * 60;
      }
      return Math.round(testTime);
    },
    changePhone() {
      this.$router.replace({
        name: "SATMobileTest",
        query: this.$route.query,
        params: this.$route.params
      });
    },
    getLine(passage) {
      let new_passage = passage.replace(/[\n]/g, "<br>");
      new_passage = new_passage.replace(
        /<br>/g,
        "</p><p style='margin-bottom:0;height:34px;white-space:nowrap;'>"
      );
      new_passage =
        "<p style='margin-bottom:0;height:34px;white-space:nowrap;'>" +
        new_passage +
        "</p>";
      return new_passage;
    },
    async startTest() {
      let res = null;
      let hasExam = false;
      if (this.practiceType === "composePractices") {
        if (this.pastUserExamId) {
          this.userExamId = this.pastUserExamId;
          res = await SAT.getPastComposePractice(this.userExamId);
          this.setUserTest(res);
        } else {
          // hasExam = await SAT.hasTakenComposePractice(this.examId);
          // if (hasExam.taken_user_exam_id) {
          //   this.$confirm(
          //     this.$t("toefl.Unfinished Test Text"),
          //     this.$t("toefl.Unfinished Test"),
          //     {
          //       showClose: false,
          //       closeOnClickModal: false,
          //       confirmButtonText: this.$t("toefl.Resume"),
          //       cancelButtonText: this.$t("toefl.Start Over")
          //     }
          //   )
          //     .then(async () => {
          //       this.userExamId = hasExam.taken_user_exam_id;
          //       res = await SAT.getPastComposePractice(
          //         hasExam.taken_user_exam_id
          //       );
          //       this.userExamAnswers = res.user_exam.user_exam_answers;
          //       this.setUserTest(res);
          //     })
          //     .catch(async () => {
          //       // res = await SAT.TakeComposePracticeUserExam(this.examId);
          //       // this.userExamId = res.user_exam.id;
          //       // this.setAnswers();
          //       // this.nextPage();
          //       this.$router.go(-1);
          //     });
          // } else {
          //   this.$router.go(-1);
          // }
        }
      } else if (this.practiceType === "adaptivePractices") {
        console.log(1);
      } else if (this.practiceType === "singlePractice") {
        if (this.pastUserExamId) {
          this.userExamId = this.pastUserExamId;
          res = await SAT.getPastPractice(this.userExamId);
          this.setUserTest(res);
        } else {
          // hasExam = await SAT.hasTakenPractice(this.examId);
          // if (hasExam.taken_user_exam_id) {
          //   this.$confirm(
          //     this.$t("toefl.Unfinished Test Text"),
          //     this.$t("toefl.Unfinished Test"),
          //     {
          //       showClose: false,
          //       closeOnClickModal: false,
          //       confirmButtonText: this.$t("toefl.Resume"),
          //       cancelButtonText: this.$t("toefl.Start Over")
          //     }
          //   )
          //     .then(async () => {
          //       this.userExamId = hasExam.taken_user_exam_id;
          //       res = await SAT.getPastPractice(hasExam.taken_user_exam_id);
          //       this.setUserTest(res);
          //     })
          //     .catch(async () => {
          //       // res = await SAT.TakePracticeUserExam(this.examId);
          //       // this.userExamId = res.user_exam.id;
          //       // this.setAnswers();
          //       // this.nextPage();
          //       this.$router.go(-1);
          //     });
          // } else {
          //   this.$router.go(-1);
          // }
        }
      }
    },
    setUserTest(res) {
      this.userExamAnswers = res.user_exam.user_exam_answers;
      this.setAnswers();
      this.sectionOrder =
        res.user_exam.other_status && res.user_exam.other_status.pastOrder
          ? res.user_exam.other_status.pastOrder.sectionOrder
          : this.sectionOrder;
      this.setSection();
      // this.sectionOrder = 1;
      this.questionOrder =
        res.user_exam.other_status && res.user_exam.other_status.pastOrder
          ? res.user_exam.other_status.pastOrder.questionOrder
          : this.questionOrder;
      this.setQuestion();
      // this.questionOrder = 1;
      this.timeType =
        res.user_exam.other_status && res.user_exam.other_status.timeType > -1
          ? res.user_exam.other_status.timeType
          : this.timeType;
      this.testTime =
        res.user_exam.other_status &&
        res.user_exam.other_status.pastOrder &&
        res.user_exam.other_status.pastOrder.testTime &&
        res.user_exam.other_status.pastOrder.testTime > 0
          ? res.user_exam.other_status.pastOrder.testTime
          : this.setExamTimeSeconds();

      if (!this.adaptiveLine.line.first) {
        this.adaptiveLine.line.first =
          res.user_exam.other_status && res.user_exam.other_status.first
            ? res.user_exam.other_status.first
            : "E";
      }
      this.answer = this.question.question["answer"];
      if (res.user_exam.other_status && res.user_exam.other_status.pastOrder) {
        this.other_status = res.user_exam.other_status;
        this.page = 2;
      } else {
        this.other_status = res.user_exam.other_status;
        this.page = 1;
      }
    },
    nextPage() {
      this.page++;
    },
    returnToHome() {
      this.$router.replace({
        name: "SATList"
      });
    },
    onReviewPage() {
      this.isReviewPage = false;
    },
    onExitTest(isEnd, seconds) {
      this.updateSatAnswers(
        isEnd,
        seconds? seconds.questionSecondsSpent: 0,
        seconds? seconds.testTime: 0
      );
      if (isEnd) {
        this.$router.replace({
          name: "SATResult",
          query: { practiceType: this.practiceType },
          params: { id: this.userExamId }
        });
      } else {
        this.$router.replace({
          name: "SATList"
        });
      }
    },
    onSwitchMarkStatus(status) {
      this.marked = status.marked;
      this.question["isMarked"] = this.marked;
    },
    onSwitchCrossOutStatus(status) {
      this.crossOutActive = status.crossOutActive;
      this.question["crossOutActive"] = this.crossOutActive;
    },
    onChangeCrossOut(status) {
      if (status.type === "crossOut") {
        if (this.crossOutArr.indexOf(status.letter) === -1) {
          this.crossOutArr.push(status.letter);
        }
        if (this.question.answer === status.letter) {
          this.answer = null;
          this.question.question.answer = null;
          this.question.answer = null;
        }
        this.question.user_exam_answer_other_status.activities[
          this.question.user_exam_answer_other_status.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: `Crossed out ${status.letter}`,
          type: null
        });
      } else if (status.type === "undo") {
        let index = this.crossOutArr.indexOf(status.letter);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
        this.question.user_exam_answer_other_status.activities[
          this.question.user_exam_answer_other_status.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: `Un-Crossed out ${status.letter}`,
          type: null
        });
      }
      this.question["crossOutArr"] = this.crossOutArr;
    },
    setPassage(passage) {
      this.question.question.sat_passage.content = passage.passageHtml
        ? passage.passageHtml
        : this.question.question.sat_passage.content;
      this.question.question.content = passage.questionHtml
        ? passage.questionHtml
        : this.question.question.content;
      if (this.question.question.sat_options) {
        this.question.question.sat_options.forEach((option, index) => {
          if (passage.optionsHtml) {
            option.title = passage.optionsHtml[index].innerHTML;
          }
        });
      }
      let annotation = {
        annotation: passage.annotation,
        passage: this.question.question.sat_passage.content,
        question: this.question.question.content,
        options: this.question.question.sat_options
      };
      this.question.user_exam_answer_other_status.annotation = annotation;
      this.$refs.QuestionWrapper.setHighlight();
      let content = passage.passage.slice(0, 10);
      this.question.user_exam_answer_other_status.activities[
        this.question.user_exam_answer_other_status.activities.length - 1
      ].push({
        testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
        content: `Annotated "${content}..."`,
        type: null
      });
    },
    async onNextQuestion(seconds) {
      if (this.questionOrder !== this.questions.length) {
        await this.updateSatAnswers(
          false,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        this.questionOrder++;
      }
    },
    async onBackQuestion(seconds) {
      if (this.questionOrder !== 0) {
        await this.updateSatAnswers(
          false,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        this.questionOrder--;
      }
    },
    async updateSatAnswers(isFinished = false, seconds = 10, testTime) {
      this.question["answer_time"] = this.questionTimeLine;
      if (
        this.question.user_exam_answer_other_status.activities &&
        this.question.user_exam_answer_other_status.activities.length > 0
      ) {
        let content = this.answer
          ? `Submitted answer ${this.answer}`
          : "Skipped";
        this.question.user_exam_answer_other_status.activities[
          this.question.user_exam_answer_other_status.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: content,
          answer: this.answer,
          type: "SUBMIT"
        });
      }
      console.log(this.question.user_exam_answer_other_status);
      // this.question.user_exam_answer_other_status.annotation = this.annotation;
      if (
        this.practiceType === "composePractices" ||
        this.practiceType === "adaptivePractices"
      ) {
        const res = await SAT.updateComposePracticeAnswers(this.userExamId, {
          exam_id: this.examId,
          exam_question_id: this.question.id,
          answers: [this.answer],
          is_finished: isFinished,
          is_collected: this.marked,
          answer_time: this.questionTimeLine,
          user_exam_answer_other_status: this.question
            .user_exam_answer_other_status,
          other_status: {
            ...this.other_status,
            pastOrder: {
              sectionOrder: this.sectionOrder,
              questionOrder: this.questionOrder,
              testTime: testTime
            },
            leaveInfo: {
              leaveTimes: this.leaveTimes,
              leaveSeconds: this.leaveSeconds
            },
            first: this.adaptiveLine.line.first
          }
        });
        this.question.is_correct = res.is_correct ? 1 : 0;
      } else if (this.practiceType === "singlePractice") {
        await SAT.updatePracticeAnswers(this.userExamId, {
          exam_question_id: this.question.id,
          answers: [this.answer],
          is_finished: isFinished,
          is_collected: this.marked,
          answer_time: this.questionTimeLine,
          user_exam_answer_other_status: this.question
            .user_exam_answer_other_status,
          other_status: {
            ...this.other_status,
            pastOrder: {
              sectionOrder: this.sectionOrder,
              questionOrder: this.questionOrder,
              testTime: testTime
            },
            leaveInfo: {
              leaveTimes: this.leaveTimes,
              leaveSeconds: this.leaveSeconds
            }
          }
        });
      }
    },
    async timeOut(seconds) {
      if (this.sectionOrder !== this.practices.length) {
        await this.updateSatAnswers(
          false,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        //适应性考试的切换单元
        if (this.practiceType === "adaptivePractices") {
          let correct_count = 0;
          this.questions.forEach(item => {
            if (item.is_correct) {
              correct_count++;
            }
          });
          if (this.sectionOrder === 1) {
            if (correct_count >= this.adaptiveLine.first_correct_count) {
              this.practices = this.adaptivePractice.compose_practices[1].practices;
              this.examId = this.adaptivePractice.compose_practices[1].exam.id;
              this.adaptiveLine.line.first = "H";
            } else {
              this.adaptiveLine.line.first = "E";
            }
          }
          if (this.sectionOrder === 3) {
            if (this.adaptiveLine.line.first === "H") {
              if (correct_count >= this.adaptiveLine.last_correct_count) {
                this.practices = this.adaptivePractice.compose_practices[3].practices;
                this.examId = this.adaptivePractice.compose_practices[3].exam.id;
              }
            } else if (this.adaptiveLine.line.first === "E") {
              if (correct_count >= this.adaptiveLine.last_correct_count) {
                this.practices = this.adaptivePractice.compose_practices[2].practices;
                this.examId = this.adaptivePractice.compose_practices[2].exam.id;
              }
            }
          }
          const data = await SAT.getPastComposePractice(this.userExamId);
          this.setUserTest(data);
        }
        this.sectionOrder++;
        this.setNextSection();
        if (
          this.breakTime[`section${this.sectionOrder - 1}${this.sectionOrder}`]
            .hasBreak
        ) {
          this.showQuestion = false;
        }
        this.isReviewPage = false;
      } else {
        await this.updateSatAnswers(
          true,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        this.isReviewPage = false;
        this.$router.replace({
          name: "SATResult",
          query: { practiceType: this.practiceType },
          params: { id: this.userExamId }
        });
      }
    },
    async onSubmitSection(
      seconds,
      remainInterval,
      questionSecondsSpentInterval
    ) {
      if (this.sectionOrder !== this.practices.length) {
        if (!this.isReviewPage) {
          this.isReviewPage = true;
          await this.updateSatAnswers(
            false,
            seconds ? seconds.questionSecondsSpent : 0,
            seconds ? seconds.testTime : 0
          );
        } else {
          clearInterval(remainInterval);
          clearInterval(questionSecondsSpentInterval);
          //适应性考试的切换单元
          if (this.practiceType === "adaptivePractices") {
            let correct_count = 0;
            this.questions.forEach(item => {
              if (item.is_correct) {
                correct_count++;
              }
            });
            if (this.sectionOrder === 1) {
              if (correct_count >= this.adaptiveLine.first_correct_count) {
                this.practices = this.adaptivePractice.compose_practices[1].practices;
                this.examId = this.adaptivePractice.compose_practices[1].exam.id;
                this.adaptiveLine.line.first = "H";
              } else {
                this.adaptiveLine.line.first = "E";
              }
            }
            if (this.sectionOrder === 3) {
              if (this.adaptiveLine.line.first === "H") {
                if (correct_count >= this.adaptiveLine.last_correct_count) {
                  this.practices = this.adaptivePractice.compose_practices[3].practices;
                  this.examId = this.adaptivePractice.compose_practices[3].exam.id;
                }
              } else if (this.adaptiveLine.line.first === "E") {
                if (correct_count >= this.adaptiveLine.last_correct_count) {
                  this.practices = this.adaptivePractice.compose_practices[2].practices;
                  this.examId = this.adaptivePractice.compose_practices[2].exam.id;
                }
              }
            }
            const data = await SAT.getPastComposePractice(this.userExamId);
            this.setUserTest(data);
          }
          this.sectionOrder++;
          this.setNextSection();
          if (
            this.breakTime[
              `section${this.sectionOrder - 1}${this.sectionOrder}`
            ].hasBreak
          ) {
            this.showQuestion = false;
          }
          this.isReviewPage = false;
        }
      } else {
        if (!this.isReviewPage) {
          this.isReviewPage = true;
          await this.updateSatAnswers(
            true,
            seconds ? seconds.questionSecondsSpent : 0,
            seconds ? seconds.testTime : 0
          );
        } else {
          this.$router.replace({
            name: "SATResult",
            query: { practiceType: this.practiceType },
            params: { id: this.userExamId }
          });
        }
      }
    },
    onTestTimeout(seconds) {
      this.timeOut(seconds);
    },
    onChangeAnswer(value) {
      if (this.crossOutArr.indexOf(value) > -1) {
        let index = this.crossOutArr.indexOf(value);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
      }
      this.answer = value;
      this.question.question.answer = value;
      this.question.answer = value;
      this.question.user_exam_answer_other_status.activities[
        this.question.user_exam_answer_other_status.activities.length - 1
      ].push({
        testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
        content: `Answer ${value}`,
        answer: this.answer,
        type: "ANSWER"
      });
    },
    onToReviewPage(order, seconds) {
      this.updateSatAnswers(false, seconds, seconds ? seconds.testTime : null);
      this.questionOrder = order;
      this.isReviewPage = true;
    },
    onOrderQuestion(order, seconds) {
      this.updateSatAnswers(false, seconds, seconds ? seconds.testTime : null);
      this.questionOrder = order;
    },
    onOrderQuestionFromReview(order) {
      this.questionOrder = order;
      this.isReviewPage = false;
    },
    setAnswers() {
      this.practices.forEach(practice => {
        practice.exam.exam_questions.forEach(question => {
          let answer = null;
          let marked = false;
          let is_correct = 0;
          let other_status = {
            activities: [],
            annotation: null
          };
          let answer_time = 0;
          if (this.userExamAnswers && this.userExamAnswers.length > 0) {
            this.userExamAnswers.forEach(userAnswer => {
              if (question.id === userAnswer.exam_question_id) {
                answer = userAnswer.answers[0];
                other_status = userAnswer.other_status
                  ? userAnswer.other_status
                  : other_status;
                answer_time = userAnswer.user_exam_answer_analysis.answer_time;
                marked =
                  userAnswer.user_exam_answer_analysis.is_collected === 1
                    ? true
                    : false;
                is_correct = userAnswer.is_correct;
              }
            });
          }
          question.question["answer"] = answer;
          question["answer"] = answer;
          question["isMarked"] = marked;
          question["user_exam_answer_other_status"] = other_status;
          question["answer_time"] = answer_time;
          question["is_correct"] = is_correct;
        });
      });
    }
  }
};
</script>

<style scoped>
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
  /* user-select: none; */
}
.test-paper {
  padding-top: 0;
  max-width: 2000px;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
/* ::v-deep p {
  margin: 0;
} */
.change-phone {
  width: 100%;
  background: var(--themeColor);
  position: fixed;
  z-index: 99999;
  bottom: 0;
  padding: 15px;
  color: white;
}
.change-phone h6 {
  margin-bottom: 15px;
  color: white;
}
.change-phone-cover {
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: block;
  }
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>
